import "react-datepicker/dist/react-datepicker.css";

import * as Yup from "yup";
import { gql, useQuery } from "@apollo/client";

import { forwardRef, useEffect, useState } from "react";

import { CalendarIcon } from "@heroicons/react/24/solid";
import Select from "react-select";
import { useFormik } from "formik";
import useQueryParams from "../../utils/query_params";

const ADMIN_ROSTER_FILTER = gql`
  query ($client: String, $personnel: String, $program: String) {
    adminRosterFilters(
      client: $client
      personnel: $personnel
      program: $program
    ) {
      clients {
        id
        clientName
      }
      personnels {
        id
        firstName
        lastName
      }
      programs {
        id
        programName
      }
      status
    }
  }
`;

// Validation schema
const validationSchema = Yup.object({
  start_date: Yup.date()
    .required("Start date is required")
    .nullable()
    .test(
      "is-before-end",
      "Start date must be before end date",
      function (value) {
        const { end_date } = this.parent;
        return !value || !end_date || new Date(value) < new Date(end_date);
      }
    ),
  end_date: Yup.date()
    .required("End date is required")
    .nullable()
    .test(
      "is-after-start",
      "End date must be after start date",
      function (value) {
        const { start_date } = this.parent;
        return !value || !start_date || new Date(value) > new Date(start_date);
      }
    ),
});

export default function Filter(props) {
  const { getQueryParam, setQueryParam, setMultipleQueryParams } =
    useQueryParams();

  const { loading, error, data, refetch } = useQuery(ADMIN_ROSTER_FILTER, {
    variables: {
      client: getQueryParam("client") ? getQueryParam("client") : "all",
      personnel: "all",
      program: "all",
      status: "all",
    },
  });

  const capitalizeFirstLetter = (string) => {
    if (!string) return string; // Handle empty or null strings
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const [clientName, setClientName] = useState(
    getQueryParam("client")
      ? {
          label: capitalizeFirstLetter(getQueryParam("client")),
          value: getQueryParam("client"),
        }
      : { label: "All", value: "all" }
  );

  const [programName, setProgramName] = useState(
    getQueryParam("program")
      ? {
          label: capitalizeFirstLetter(getQueryParam("program")),
          value: getQueryParam("program"),
        }
      : { label: "All", value: "all" }
  );
  const [personnelName, setPersonnelName] = useState(
    getQueryParam("personnel")
      ? {
          label: capitalizeFirstLetter(getQueryParam("personnelLabel")),
          value: getQueryParam("personnel"),
        }
      : { label: "All", value: "all" }
  );

  const formik = useFormik({
    initialValues: {
      start_date: getQueryParam("startDate"),
      end_date: getQueryParam("endDate"),
    },
    validationSchema,
    onSubmit: (values) => {
      console.log({
        variables: {
          start_date: values.start_date,
          end_date: values.end_date,
        },
      });
    },
  });

  const [clientNameOptions, setClientNameOptions] = useState([]);
  const [programNameOptions, setProgramNameOptions] = useState([]);
  const [personnelNameOptions, setPersonnelNameOptions] = useState([]);

  useEffect(() => {
    let dates = formik.values;
    setMultipleQueryParams({
      startDate: parseValue(dates.start_date),
      endDate: parseValue(dates.end_date),
      // page: 1,
    });
  }, [formik.values.start_date, formik.values.end_date]);

  useEffect(() => {
    // Update formik values when data is available
    if (data && data.adminRosterFilters) {
      let programs = data.adminRosterFilters.programs.map((program) => ({
        label: program.programName,
        value: program.id,
      }));
      setProgramNameOptions([...[{ label: "All", value: "all" }], ...programs]);

      let clients = data.adminRosterFilters.clients.map((program) => ({
        label: program.clientName,
        value: program.id,
      }));
      setClientNameOptions([...[{ label: "All", value: "all" }], ...clients]);

      let personnels = data.adminRosterFilters.personnels.map((personnel) => ({
        label: `${personnel.firstName} ${personnel.lastName}`,
        value: personnel.id,
      }));

      setPersonnelNameOptions([
        ...[{ label: "All", value: "all" }],
        ...personnels,
      ]);
    }
  }, [data?.adminRosterFilters]);

  const parseValue = (date) => {
    //return date ? date.toISOString().split("T")[0] : null;

    if (!date) return null;

    try {
      const parsedDate =
        typeof date === "object" && date.year && date.month && date.day
          ? new Date(date.year, date.month - 1, date.day)
          : new Date(date);

      return !isNaN(parsedDate) ? parsedDate.toISOString().split("T")[0] : null;
    } catch (error) {
      console.error("Error parsing date:", error);
      return null;
    }
  };

  // Custom input component to include calendar icon
  const CustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
    <div
      onClick={onClick}
      className="relative w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 flex items-center"
    >
      <input
        ref={ref}
        value={value}
        onChange={onChange}
        className="flex-grow outline-none w-full"
        placeholder="Select date"
        readOnly
      />
      <CalendarIcon className="h-5 w-5 text-gray-400 ml-2" />
    </div>
  ));

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className="space-y-12">
          <div className="grid grid-cols-2 sm:grid-cols-5 gap-x-6 gap-y-5 items-start">
            <div className="col-span-1">
              <label
                htmlFor="start_date"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Start date
              </label>

              <div className="mt-2 space-y-2">
                <input
                  autoFocus
                  id="start_date"
                  name="start_date"
                  type="date"
                  onChange={(e) => {
                    formik.setFieldValue("start_date", e.target.value);
                  }}
                  defaultValue={getQueryParam("startDate")}
                  className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  max="9999-12-31"
                  // onKeyDown={(e) => e.preventDefault()}
                />
              </div>
            </div>

            <div className="col-span-1">
              <label
                htmlFor="end_date"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                End date
              </label>

              <div className="mt-2 space-y-2">
                <input
                  id="end_date"
                  name="end_date"
                  type="date"
                  onChange={(e) => {
                    formik.setFieldValue("end_date", e.target.value);
                  }}
                  defaultValue={getQueryParam("endDate")}
                  // value={getQueryParam("endDate")}
                  className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  max="9999-12-31"
                  // onKeyDown={(e) => e.preventDefault()}
                />
              </div>
            </div>

            <div className="col-span-1">
              <label
                htmlFor="react-select-program_name-input"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Program Name
              </label>

              <div className="mt-2 space-y-2">
                <Select
                  options={programNameOptions}
                  name="program_name"
                  id="program_name"
                  instanceId="program_name"
                  value={{
                    label: programName.label,
                    value: programName.value,
                  }}
                  onChange={(selected) => {
                    formik.setFieldValue("program_name", selected.value);
                    setMultipleQueryParams({
                      program: selected.value,
                      page: 1,
                    });
                    setProgramName({
                      label: selected.label,
                      value: selected.value,
                    });
                  }}
                  className="block w-full rounded-md text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />

                {formik.touched.client_name && formik.errors.client_name ? (
                  <div className="text-red-600 text-sm">
                    {formik.errors.client_name}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="col-span-1">
              <label
                htmlFor="react-select-client_name-input"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Client Name
              </label>

              <div className="mt-2 space-y-2">
                <Select
                  options={clientNameOptions}
                  name="client_name"
                  id="client_name"
                  instanceId="client_name"
                  value={{
                    label: clientName.label,
                    value: clientName.value,
                  }}
                  onChange={(selected) => {
                    formik.setFieldValue("client_name", selected.value);
                    setMultipleQueryParams({
                      client: selected.value,
                      page: 1,
                    });
                    setClientName(selected);
                  }}
                  className="block w-full rounded-md text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />

                {formik.touched.client_name && formik.errors.client_name ? (
                  <div className="text-red-600 text-sm">
                    {formik.errors.client_name}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="col-span-1">
              <label
                htmlFor="react-select-personnel_name-input"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Personnel Name
              </label>

              <div className="mt-2 space-y-2">
                <Select
                  options={personnelNameOptions}
                  name="personnel_name"
                  id="personnel_name"
                  instanceId="personnel_name"
                  value={{
                    label: personnelName.label,
                    value: personnelName.value,
                  }}
                  onChange={(selected) => {
                    formik.setFieldValue("personnel_name", selected.value);
                    setMultipleQueryParams({
                      personnel: selected.value,
                      personnelLabel: selected.label,
                      page: 1,
                    });
                    setPersonnelName({
                      label: selected.label,
                      value: selected.value,
                    });
                  }}
                  className="block w-full rounded-md text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />

                {formik.touched.personnel_name &&
                formik.errors.personnel_name ? (
                  <div className="text-red-600 text-sm">
                    {formik.errors.personnel_name}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
