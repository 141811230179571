import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline";
import { gql, useMutation, useQuery } from "@apollo/client";

import { Layout } from "@components";
import { Link } from "react-router-dom";
import Loader from "../../components/Loader";
import ReactPaginate from "react-paginate";
import { useState } from "react"; // Import useState hook
import formatNumber from "../../utils/formatNumber";

const ALL_PERSONNELS_QUERY = gql`
  query ($page: Int) {
    allPersonnels(page: $page) {
      entries {
        referralTax
        commissionTax
        email
        firstName
        id
        inactiveDate
        lastName
        rosterTax
        status
      }
      pageNumber
      pageSize
      totalPages
      totalEntries
    }
  }
`;

const DELETE_PERSONNEL_MUTATION = gql`
  mutation ($id: String!) {
    deletePersonnel(id: $id) {
      personnel {
        id
      }
    }
  }
`;

export default function Personnel() {
  const [page, setPage] = useState(1);

  const { loading, error, data, refetch } = useQuery(ALL_PERSONNELS_QUERY, {
    variables: { page },
  });

  const handlePageClick = (e) => {
    setPage(e.selected + 1);
    refetch({ page: e.selected + 1 });
  };

  const [delete_personnel] = useMutation(DELETE_PERSONNEL_MUTATION, {
    onCompleted: (data) => {
      if (data && data.deletePersonnel) {
        window.location.replace(`/personnel`);
      } else {
        alert("Something went wrong");
      }
    },
    onError: (error) => {
      alert("Something went wrong");
    },
  });

  if (loading) return <Loader />;
  if (error) return <h1>Something went wrong!</h1>;

  const startOfResults = data.allPersonnels.pageNumber
    ? (data.allPersonnels.pageNumber - 1) * data.allPersonnels.pageSize + 1
    : 0;
  const endOfResults = data.allPersonnels.totalEntries
    ? Math.min(
        startOfResults + data.allPersonnels.pageSize - 1,
        data.allPersonnels.totalEntries
      )
    : 0;

  return (
    <Layout>
      <div className="py-10 lg:pl-72">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 ">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-base font-semibold leading-6 text-gray-900">
                Personnel
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                A list of all the personnel
              </p>
            </div>
            <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
              <Link
                to="/personnel/new"
                className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Add personnel
              </Link>
            </div>
          </div>
        </div>
        <div className="mt-8 flow-root overflow-hidden">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <table className="w-full text-left">
              <thead className="bg-white">
                <tr>
                  <th
                    scope="col"
                    className="relative isolate py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                  >
                    Personnel
                    <div className="absolute inset-y-0 right-full -z-10 w-screen border-b border-b-gray-200" />
                    <div className="absolute inset-y-0 left-0 -z-10 w-screen border-b border-b-gray-200" />
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                  >
                    Email
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                  >
                    Roster Tax
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                  >
                    Commission Tax
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                  >
                    Referral Tax
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.allPersonnels.entries.map((item) => (
                  <tr key={item.id} className="cursor-pointer hover:bg-gray-50">
                    <td className="relative py-1 pr-3 text-sm font-medium text-gray-900">
                      {item?.firstName} {item?.lastName}
                      <div className="absolute bottom-0 right-full h-px w-screen bg-gray-100" />
                      <div className="absolute bottom-0 left-0 h-px w-screen bg-gray-100" />
                    </td>
                    <td className="hidden px-3 py-1 text-sm text-gray-500 sm:table-cell">
                      {item?.email}
                    </td>
                    <td className="px-3 py-1 text-sm text-gray-500 sm:table-cell text-center">
                      {formatNumber(item?.rosterTax, 2, 2)}
                    </td>
                    <td className="hidden text-sm text-gray-500 sm:table-cell text-center">
                      {formatNumber(item?.commissionTax, 2, 2)}
                    </td>
                    <td className="hidden text-sm text-gray-500 sm:table-cell text-center">
                      {formatNumber(item?.referralTax,2 ,2)}
                    </td>
                    <td className="px-3 py-1 text-sm text-gray-500">
                      {item.status === "active" ? (
                        <span className="inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                          ACTIVE
                        </span>
                      ) : (
                        <span className="inline-flex items-center rounded-full bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
                          INACTIVE
                        </span>
                      )}
                    </td>
                    <td className="flex flex-row gap-1 items-center px-3 py-1 ">
                      {item?.status == "active" && (
                        <Link
                          to={`/personnel/${item.id}/edit`}
                          className="block rounded-md bg-indigo-600 px-3 py-1 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                          Edit
                        </Link>
                      )}

                      {item?.status == "active" && (
                        <button
                          className="rounded-md bg-red-600 px-3 py-1 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                          onClick={(e) => {
                            const confirmed = window.confirm(
                              "Are you sure you want to deactivate this personnel?"
                            );
                            if (confirmed) {
                              delete_personnel({
                                variables: {
                                  id: item?.id,
                                },
                              });
                            }
                          }}
                        >
                          Deactivate
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {data && (
              <div className="py-4 flex flex-row items-center justify-between mt-6">
                <div className="text-sm text-gray-700">
                  <p>
                    Showing{" "}
                    <span className="font-medium">{startOfResults}</span> to{" "}
                    <span className="font-medium">{endOfResults}</span> of{" "}
                    <span className="font-medium">
                      {data.allPersonnels.totalEntries}
                    </span>{" "}
                    results
                  </p>
                </div>
                <ReactPaginate
                  breakLabel="..."
                  nextLabel={
                    <span>
                      <span className="sr-only">Next</span>
                      <svg
                        className="h-5 w-5 ml-2"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                  }
                  previousLabel={
                    <span>
                      <svg
                        className="h-5 w-5 mr-2"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <span className="sr-only">Previous</span>
                    </span>
                  }
                  breakLinkClassName="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 cursor-not-allowed"
                  pageClassName="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  containerClassName="isolate inline-flex -space-x-px rounded-md shadow-sm"
                  activeClassName="z-10 bg-indigo-600 text-white ring-1 ring-indigo-600"
                  previousLinkClassName="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  nextLinkClassName="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={data.allPersonnels.totalPages}
                  renderOnZeroPageCount={null}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
}
