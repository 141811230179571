import React, { useState } from "react";

//import { Link } from "react-router-dom";
import formatInteger from "../../utils/formatInteger";
import formatNumber from "../../utils/formatNumber";

function ProgramDetail({ label, value }) {
  return (
    <div className="flex flex-row justify-between">
      <p className="text-xs font-normal leading-5 text-gray-700">{label}</p>
      <p className="text-xs font-normal leading-5 text-gray-700">{value}</p>
    </div>
  );
}

function SectionHeader({ title }) {
  return (
    <p className="text-sm font-semibold leading-6 text-gray-800">{title}</p>
  );
}

function ProgramDetails({ program }) {
  if (!program) return null; // Render nothing if program is null or undefined

  return (
    <div className="border-gray-200 py-6 px-4 sm:px-6 lg:px-8">
      <SectionHeader title="Program Details" />
      <ProgramDetail label="Name" value={program.programName} />
      <ProgramDetail label="Venue" value={program.programVenue} />
      <ProgramDetail
        label="No. of Pax"
        value={formatInteger(program.programPax)}
      />
      <ProgramDetail
        label="Indoor modular %"
        value={formatInteger(program.programTypeIndoorModular)}
      />
      <ProgramDetail
        label="Teambuilding %"
        value={formatInteger(program.programTypeTeambuilding)}
      />
      <ProgramDetail
        label="Consultation %"
        value={formatInteger(program.programTypeConsultation)}
      />
      <ProgramDetail
        label="Indoor convention %"
        value={formatInteger(program.programTypeIndoorConvention)}
      />
    </div>
  );
}

function ProgramTools({ program }) {
  if (!program) return null; // Render nothing if program is null or undefined

  return (
    <div className="border-gray-200 border-t py-6 px-4 sm:px-6 lg:px-8 md:border-t-0 lg:border-l">
      <SectionHeader title="Program Tools" />
      <ProgramDetail label="Name" value={program.programToolName} />
      <ProgramDetail
        label="Cost"
        value={formatInteger(program.programToolCost)}
      />
      <ProgramDetail
        label="Price"
        value={formatInteger(program.programToolPrice)}
      />
      <ProgramDetail
        label="Quantity"
        value={formatInteger(program.programToolQuantity)}
      />
    </div>
  );
}

function ProgramFinancials({ program }) {
  if (!program) return null; // Render nothing if program is null or undefined

  return (
    <div className="col-span-1 border-gray-200 border-t py-6 px-4 sm:px-6 sm:col-span-2 lg:col-span-1 lg:px-8 lg:border-t-0 sm:border-l">
      <SectionHeader title="Program Financials" />
      <ProgramDetail
        label="Overhead"
        value={formatInteger(program.programOverhead)}
      />
      <ProgramDetail
        label="Management Price"
        value={formatInteger(program.programContractPrice)}
      />
    </div>
  );
}

function ProgramTeamProfile({ program }) {
  if (!program) return null; // Render nothing if program is null or undefined

  return (
    <div className="border-gray-200 border-t py-6 px-4 sm:px-6 lg:px-8 lg:border-t-0 lg:border-l">
      <SectionHeader title="Program Team Profile" />
      <ProgramDetail label="Name" value={program.programTeamProfileName} />
      <ProgramDetail
        label="Cost"
        value={formatInteger(program.programTeamProfileCost)}
      />
      <ProgramDetail
        label="Price"
        value={formatInteger(program.programTeamProfilePrice)}
      />
      <ProgramDetail
        label="Quantity"
        value={formatInteger(program.programTeamProfileQuantity)}
      />
    </div>
  );
}

function ProgramPricingAlert({ program }) {
  if (!program) return null; // Render nothing if program is null or undefined

  return (
    <>
      {!program?.isInvoiceEqualToContractPrice && (
        <div className="border-l-4 border-red-400 bg-red-50 p-4">
          <div className="flex mx-auto max-w-7xl px-0 sm:px-6 lg:px-8">
            <div className="flex-shrink-0">
              <svg
                className="h-5 w-5 text-red-400"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z"
                  clipRule="evenodd"
                />
              </svg>
            </div>

            <div className="ml-3">
              <p className="text-sm text-red-700">
                Total Invoices of {formatNumber(program?.totalInvoicePrice)}{" "}
                does not match Contract Price of{" "}
                {formatNumber(program?.totalContractPrice)}.
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

function SalesTeamAlert({ salesCount }) {
  return (
    <>
      {salesCount === 0 && (
        <div className="border-l-4 border-red-400 bg-red-50 p-4">
          <div className="flex mx-auto max-w-7xl px-0 sm:px-6 lg:px-8 m-auto justify-center">
            <div className="flex-shrink-0">
              <svg
                className="h-5 w-5 text-red-400"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z"
                  clipRule="evenodd"
                />
              </svg>
            </div>

            <div className="ml-3">
              <p className="text-sm text-red-700">
                No Sales Team member(s) added.
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default function Header({ program, salesCount }) {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleDisclosure = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <header>
        {program && program.programStatus === "draft" ? (
          <div className="mx-auto flex-row items-center gap-x-8 px-4 py-4  sm:px-6 lg:px-8 text-center font-semibold text-3xl bg-gray-500 text-white">[{program.programStatus.toUpperCase()}]</div>
        ) : null}
        {program && program.programStatus === "completed" ? (
          <div className="mx-auto flex-row items-center gap-x-8 px-4 py-4  sm:px-6 lg:px-8 text-center font-semibold text-3xl bg-green-700 text-white">[{program.programStatus.toUpperCase()}]</div>
        ) : null}
        {program && program.programStatus === "cancelled" ? (
          <div className="mx-auto flex-row items-center gap-x-8 px-4 py-4  sm:px-6 lg:px-8 text-center font-semibold text-3xl bg-red-500 text-white">[{program.programStatus.toUpperCase()}]</div>
        ) : null}
        {/* <div className="mx-auto max-w-7xl flex flex-row items-center justify-between gap-x-8 gap-y-4 px-4 py-4  sm:px-6 lg:px-8">
          <div>
            <div className="flex items-center gap-x-3">
              <h1 className="flex gap-x-3 text-base leading-7">
                <span className="text-lg font-semibold text-gray-600">
                {program ? program.clientName : ""}
                </span>
                <span className="font-semibold text-gray-600">
                  {program && program.clientDepartment != null ? "| " + program.clientDepartment : ""}
                </span>
              </h1>
            </div>
            <p className="mt-0/5 text-xs font-normal text-gray-700">
              {program ? program.clientContactName : ""}
            </p>
            <p className="text-xs font-normal text-gray-700">
              {program ? program.clientContactDetails : ""}
            </p>
          </div>
          <div className="flex flex-row items-center space-x-4">
            {isExpanded && (
              <div className="relative group flex">
                <Link
                  to={`/programs/${program?.id}/edit`}
                  className="inline-block rounded-md bg-indigo-600 px-3 py-2 text-center text-xs font-normal text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 whitespace-nowrap"
                >
                  <PencilSquareIcon className="h-4 w-5 text-white block sm:hidden" />{" "}
                  <spam className="hidden sm:block"> Edit Program</spam>
                </Link>

                <div className="absolute hidden w-48 px-2 py-1 text-xs text-white bg-blue-500 rounded-md shadow-lg group-hover:flex left-auto right-full ml-0 mr-3 top-1/2 -translate-y-1/2">
                  Update program details
                  <div className="absolute h-3 w-3 bg-blue-500 transform -rotate-45 -right-2 sm:left-auto sm:-right-1.5 top-1/2 -translate-y-1/2"></div>
                </div>
              </div>
            )}
            <div className="relative group flex">
              <div
                onClick={toggleDisclosure}
                className="cursor-pointer transition-transform duration-300 transform hover:scale-105"
              >
                {isExpanded ? (
                  <ChevronUpIcon className="h-6 w-6 text-gray-500" />
                ) : (
                  <ChevronDownIcon className="h-6 w-6 text-gray-500" />
                )}
              </div>
              {/* Tooltip for toggle message /}
              {/* Tooltip container with blue color for an informative look /}
              <div className="absolute hidden w-48 px-2 py-1 text-xs text-white bg-blue-500 rounded-md shadow-lg group-hover:flex left-auto right-full ml-0 mr-3 top-1/2 -translate-y-1/2">
                {isExpanded
                  ? "Click to hide program details"
                  : "Click to show program details"}
                {/* Tooltip arrow with matching blue color /}
                <div className="absolute h-3 w-3 bg-blue-500 transform -rotate-45 -right-2 sm:left-auto sm:-right-1.5 top-1/2 -translate-y-1/2"></div>
              </div>
            </div>
          </div>
        </div> */}

        {/* <div
          className={`border-t  border-gray-200 transition-height duration-300 ${
            isExpanded ? "h-auto border-b" : "h-0 overflow-hidden"
          }`}
        >
          <div className="mx-auto max-w-7xl grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4">
            <ProgramDetails program={program} />
            <ProgramTools program={program} />
            <ProgramTeamProfile program={program} />
            <ProgramFinancials program={program} />
          </div>
        </div> */}
      </header>

      <ProgramPricingAlert program={program} />
      <SalesTeamAlert salesCount={salesCount} />
    </>
  );
}
