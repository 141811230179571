import Filter from "./filter";
import List from "./list";
import { Layout } from "@components";
import { Link } from "react-router-dom";

export default function Program() {
  return (
    <Layout>
      <div className="py-10 lg:pl-72">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 ">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-base font-semibold leading-6 text-gray-900">
                Programs
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                A list of all the projects
              </p>
            </div>

            <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
              <Link
                to="/programs/new"
                className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Add program
              </Link>
            </div>
          </div>

          <div>
            <Filter />
          </div>
        </div>

        <List />
      </div>
    </Layout>
  );
}
