import "react-toastify/dist/ReactToastify.css";

import { gql, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";

import Filter from "./filter";
import { Layout } from "@components";
import Loader from "../../components/Loader";
import ReactPaginate from "react-paginate";
import StatusSelect from "./status";
import { ToastContainer } from "react-toastify";
import formatNumber from "../../utils/formatNumber";
import moment from "moment";
import { useLocation } from "react-router-dom";
import useQueryParams from "../../utils/query_params";

const ADMIN_INVOICES_QUERY = gql`
  query (
    $page: Int!
    $pageSize: Int!
    $startDate: String
    $endDate: String
    $client: String
    $program: String
    $status: String
  ) {
    adminInvoices(
      page: $page
      pageSize: $pageSize
      startDate: $startDate
      endDate: $endDate
      client: $client
      program: $program
      status: $status
    ) {
      entries {
        billedTo
        hasPaidSales
        changedBy {
          id
          email
        }
        id
        invoiceDate
        price
        program {
          id
          clientName
          clientContactName
          clientContactDetails
          programName
        }
        status
        statusDate
        updatedAt
      }
      pageNumber
      pageSize
      totalPages
      totalEntries
    }
  }
`;

export default function Invoices() {
  const location = useLocation();
  const { getQueryParam, setQueryParam, setMultipleQueryParams } =
    useQueryParams();
  const [page, setPage] = useState(
    getQueryParam("page") ? parseInt(getQueryParam("page")) : 1
  );
  const [pageSize, setPageSize] = useState(
    getQueryParam("pageSize") ? parseInt(getQueryParam("pageSize")) : 10
  );
  const [queryFilters, setQueryFilters] = useState({ page: 1 });

  const getQueryParamOrNull = (params) => {
    const value = getQueryParam(params);
    return value === "all" ? null : value;
  };

  const { loading, error, data, refetch } = useQuery(ADMIN_INVOICES_QUERY, {
    variables: {
      page: getQueryParam("page") ? parseInt(getQueryParam("page")) : 1,
      pageSize: getQueryParam("pageSize")
        ? parseInt(getQueryParam("pageSize"))
        : 10,
    },
  });

  useEffect(() => {
    if (getQueryParam("page")) {
      setPage(parseInt(getQueryParam("page")));
    }
    refetch({
      page: getQueryParam("page") ? parseInt(getQueryParam("page")) : 1,
      pageSize: getQueryParam("pageSize")
        ? parseInt(getQueryParam("pageSize"))
        : 10,
      startDate: getQueryParamOrNull("startDate"),
      endDate: getQueryParamOrNull("endDate"),
      client: getQueryParam("client") ? getQueryParam("client") : "all",
      program: getQueryParam("program") ? getQueryParam("program") : "all",
      status: getQueryParam("status") ? getQueryParam("status") : "all",
    });
  }, [location.search]);

  const handlePageClick = (e) => {
    const newPage = e.selected + 1;
    setPage(newPage);
    setQueryParam("page", parseInt(newPage));
  };

  if (loading) return <Loader />;
  if (error) return <h1>Something went wrong!</h1>;

  const startOfResults = data.adminInvoices.pageNumber
    ? (data.adminInvoices.pageNumber - 1) * data.adminInvoices.pageSize + 1
    : 0;

  return (
    <Layout>
      <ToastContainer />
      <div className="py-10 lg:pl-72">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 ">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-base font-semibold leading-6 text-gray-900">
                Invoices
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                A list of all the invoices
              </p>
            </div>
          </div>
        </div>
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-5">
          <Filter
            setQueryFilters={setQueryFilters}
            setPage={setPage}
            filter={refetch}
          />
        </div>
        <div className="mt-8 flow-root overflow-hidden">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <table className="w-full text-left">
              <thead className="bg-white">
                <tr>
                  <th
                    scope="col"
                    className="relative isolate process-th min-w-24"
                  >
                    Date
                    <div className="absolute inset-y-0 right-full -z-10 w-screen border-b border-b-gray-200" />
                    <div className="absolute inset-y-0 left-0 -z-10 w-screen border-b border-b-gray-200" />
                  </th>
                  <th scope="col" className="process-th min-w-64">
                    Program
                  </th>
                  <th scope="col" className="process-th min-w-40">
                    Billed To
                  </th>
                  <th scope="col" className="process-th min-w-20">
                    Amount
                  </th>
                  <th scope="col" className="process-th min-w-24">
                    Contact
                  </th>
                  {/* <th
                    scope="col"
                    className="process-th"
                  >
                    Contact Details
                  </th> */}
                  <th scope="col" className="process-th min-w-36 ">
                    Updated By
                  </th>
                  {/* <th
                    scope="col"
                    className="process-th"
                  >
                    Updated By
                  </th> */}
                  <th scope="col" className="process-th">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.adminInvoices.entries.map((item) => (
                  <tr
                    id={item.id}
                    key={item.id}
                    className="cursor-pointer hover:bg-gray-50"
                  >
                    <td className="relative process-results">
                      {moment(item.invoiceDate).format("YYYY-MMM-DD")}
                      <div className="absolute bottom-0 right-full h-px w-screen bg-gray-100" />
                      <div className="absolute bottom-0 left-0 h-px w-screen bg-gray-100" />
                    </td>
                    <td className="process-results">
                      {item?.program?.programName}
                      <br />
                      {item?.program?.clientName}
                    </td>
                    <td className="process-results max-w-40">
                      {item?.billedTo}
                    </td>
                    <td className="process-results text-right">
                      {formatNumber(item?.price)}
                    </td>
                    <td className="process-results min-w-24 max-w-96 text-wrap break-all">
                      {item?.program?.clientContactName}
                      <br />
                      {item?.program?.clientContactDetails}
                    </td>
                    {/* <td className="process-results">
                      {item?.program?.clientContactDetails}
                    </td> */}
                    <td className="process-results">
                      {item.changedBy?.email}
                      <br />
                      {item.statusDate
                        ? moment(item.statusDate).format("YYYY-MMM-DD HH:mm.ss")
                        : "N/A"}
                    </td>
                    {/* <td className="process-results">
                      {item.changedBy?.email}
                    </td> */}
                    <td className="process-results px-3 py-4">
                      <div className={`w-48 text-xs ${item.hasPaidSales ? 'flex justify-center items-center' : ''}`}>
                        {" "}
                        {/* Adjust width here */}
                        {!item.hasPaidSales ?
                        <StatusSelect
                          id={item.id}
                          selectedStatus={item.status}
                          refetch={refetch}
                          error={error}
                        /> : (
                          <span className="text-center">{item.status}</span>
                        )
                        }
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {data && (
              <div className="py-4 flex flex-row items-center justify-between mt-6">
                <div className="flex flex-wrap items-center justify-between text-sm text-gray-700">
                  <div className="flex items-center space-x-2">
                    <span>
                      Showing{" "}
                      <span className="font-medium">{startOfResults}</span> to
                    </span>
                    <label htmlFor="pageSize" className="sr-only">
                      Results per page:
                    </label>
                    <select
                      id="pageSize"
                      name="pageSize"
                      value={pageSize}
                      onChange={(e) => {
                        setPageSize(parseInt(e.target.value, 10));
                        setQueryParam("pageSize", parseInt(e.target.value, 10));
                      }}
                      className="rounded-md bg-white py-1.5 px-3 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm"
                    >
                      <option value={10}>10</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                      <option value={1000}>1000</option>
                    </select>
                    <span>
                      of{" "}
                      <span className="font-medium">
                        {data.adminInvoices.totalEntries}
                      </span>{" "}
                      results
                    </span>
                  </div>
                </div>

                <ReactPaginate
                  breakLabel="..."
                  nextLabel={
                    <span>
                      <span className="sr-only">Next</span>
                      <svg
                        className="h-5 w-5 ml-2"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                  }
                  previousLabel={
                    <span>
                      <svg
                        className="h-5 w-5 mr-2"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <span className="sr-only">Previous</span>
                    </span>
                  }
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={3}
                  pageCount={data.adminInvoices.totalPages} // Total number of pages
                  forcePage={page - 1} // Sync with the current page (zero-indexed)
                  breakLinkClassName="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 cursor-not-allowed"
                  pageClassName="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  containerClassName="isolate inline-flex -space-x-px rounded-md shadow-sm"
                  activeClassName="z-10 bg-indigo-600 text-white ring-1 ring-indigo-600"
                  previousLinkClassName="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  nextLinkClassName="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
}
