import * as Yup from "yup";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import Select from "react-select";
import { useFormik } from "formik";
import useQueryParams from "../../utils/query_params";

const PROGRAM_FILTER = gql`
  query ($client: String, $program: String, $status: String) {
    programFilters(client: $client, program: $program, status: $status) {
      status
      statuses
      programs {
        id
        programName
      }
      clients {
        id
        clientName
      }
    }
  }
`;

// Validation schema
const validationSchema = Yup.object({
  client_name: Yup.string(),
  program_name: Yup.string(),
  program_status: Yup.string(),
});

export default function Filter() {
  const { getQueryParam, setMultipleQueryParams } =
    useQueryParams();

  const capitalizeFirstLetter = (string) => {
    if (!string) return string; // Handle empty or null strings
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const { loading, error, data, refetch } = useQuery(PROGRAM_FILTER, {
    fetchPolicy: "no-cache",
    variables: {
      client: getQueryParam("client") ? getQueryParam("client") : "all",
      program: "all",
      status: "all",
    },
  });
  const [clientName, setClientName] = useState(
    getQueryParam("client")
      ? {
          value: getQueryParam("client"),
          label: capitalizeFirstLetter(getQueryParam("client")),
        }
      : { label: "All", value: "all" }
  );

  const [programName, setProgramName] = useState(
    getQueryParam("program")
      ? {
          value: getQueryParam("program"),
          label: capitalizeFirstLetter(getQueryParam("program")),
        }
      : { label: "", value: "" }
  );

  const [status, setStatus] = useState(
    getQueryParam("status")
      ? {
          value: getQueryParam("status"),
          label: capitalizeFirstLetter(getQueryParam("status")),
        }
      : { label: "All", value: "all" }
  );

  const [statusOptions, setStatusOptions] = useState([
    { label: "All", value: "all" },
    { label: "Draft", value: "draft" },
    { label: "Cancelled", value: "cancelled" },
    { label: "Active", value: "active" },
    { label: "Completed", value: "completed" },
  ]);

  const [clientNameOptions, setClientNameOptions] = useState([]);
  const [programNameOptions, setProgramNameOptions] = useState([]);

  useEffect(() => {
    // Update formik values when data is available
    if (data && data.programFilters) {
      let programs = data.programFilters.programs.map((program) => ({
        label: program.programName,
        value: program.id,
      }));
      setProgramNameOptions([...[{ label: "All", value: "all" }], ...programs]);

      let clients = data.programFilters.clients.map((program) => ({
        label: program.clientName,
        value: program.id,
      }));
      setClientNameOptions([...[{ label: "All", value: "all" }], ...clients]);
    }
  }, [data?.programFilters]);

  const formik = useFormik({
    initialValues: {
      client_name: "",
      program_name: "",
      program_status: "",
    },
    validationSchema,
    onSubmit: (values) => {
      console.log({
        variables: {
          client_name: values.client_name,
          program_name: values.program_name,
          program_status: values.program_status,
        },
      });
    },
  });

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className="space-y-12 -mt-5">
          <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-9 md:col-span-2 items-start">
            <div className="sm:col-span-3">
              <label
                htmlFor="program_name"
                className="text-sm font-medium text-gray-900 label-on-input"
              >
                Program Name
              </label>

              <div className="mt-2 space-y-2">
                <input
                  autoFocus
                  id="program_name"
                  name="program_name"
                  type="text"
                  //onBlur={formik.handleBlur}
                  className="block w-full rounded-md py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"       
                  placeholder="Enter program name"
                  defaultValue={programName.value} // Ensure input value is controlled
                  onChange={(e) => {
                    const newValue = e.target.value; // Get the new value from input
                
                    formik.setFieldValue("program_name", newValue); // Update Formik state
                
                    setMultipleQueryParams({
                      program: newValue, // Use the new value
                      page: 1,
                    });
                
                    setProgramName({
                      label: newValue,
                      value: newValue,
                    });
                
                    console.log(newValue);
                  }}
                />
                {/* <Select
                  autoFocus
                  options={programNameOptions}
                  name="program_name"
                  id="program_name"
                  instanceId="program_name"
                  value={{
                    label: programName.label,
                    value: programName.value,
                  }}
                  onChange={(selected) => {
                    formik.setFieldValue("program_name", selected.value);
                    // refetch({
                    //   program: selected.value,
                    //   client: clientName.value,
                    //   status: "all",
                    // });
                    setMultipleQueryParams({
                      program: selected.value,
                      //status: "all",
                      page: 1,
                    });
                    setProgramName({
                      label: selected.label,
                      value: selected.value,
                    });
                    setStatus({ label: "All", value: "all" });
                    // props.refetch({
                    //   page: 1,
                    //   client: clientName.value,
                    //   program: selected.value,
                    //   status: "all",
                    // });
                  }}
                  className="block w-full rounded-md text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />

                {formik.touched.client_name && formik.errors.client_name ? (
                  <div className="text-red-600 text-sm">
                    {formik.errors.client_name}
                  </div>
                ) : null} */}
              </div>
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="react-select-client_name-input"
                className="text-sm font-medium text-gray-900 label-on-input z-20"
              >
                Client Name
              </label>

              <div className="mt-2 space-y-2">
                <Select
                  options={clientNameOptions}
                  name="client_name"
                  id="client_name"
                  instanceId="client_name"
                  value={{
                    label: clientName.label,
                    value: clientName.value,
                  }}
                  onChange={(selected) => {
                    formik.setFieldValue("client_name", selected.value);
                    // refetch({
                    //   client: selected.value,
                    //   program: "all",
                    //   status: "all",
                    // });
                    setMultipleQueryParams({
                      client: selected.value,
                      //program: "all",
                      //status: "all",
                      page: 1,
                    });
                    setClientName(selected);
                    //setProgramName({ label: "All", value: "all" });
                    //setStatus({ label: "All", value: "all" });
                    // props.refetch({
                    //   page: 1,
                    //   client: selected.value,
                    //   program: "all",
                    //   status: "all",
                    // });
                  }}
                  className="block w-full rounded-md text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />

                {formik.touched.client_name && formik.errors.client_name ? (
                  <div className="text-red-600 text-sm">
                    {formik.errors.client_name}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="react-select-status-input"
                className="text-sm font-medium text-gray-900 label-on-input z-10"
              >
                Status
              </label>

              <div className="mt-2 space-y-2">
                <Select
                  options={statusOptions}
                  name="status"
                  id="status"
                  instanceId="status"
                  value={{
                    label: status.label,
                    value: status.value,
                  }}
                  onChange={(selected) => {
                    formik.setFieldValue("program_status", selected.value);
                    setStatus(selected);
                    setMultipleQueryParams({
                      status: selected.value,
                      page: 1,
                    });
                    // props.refetch({
                    //   page: 1,
                    //   client: clientName.value,
                    //   program: programName.value,
                    //   status: selected.value,
                    // });
                  }}
                  className="capitalize block w-full rounded-md text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                {formik.touched.program_status &&
                formik.errors.program_status ? (
                  <div className="text-red-600 text-sm">
                    {formik.errors.program_status}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
