import * as Yup from "yup";

import { Layout, ModalAlert } from "@components";
import Filter from "./filter";
import List from "./list";

export default function Allowance() {
  return (
    <Layout>
      <div className="py-10 lg:pl-72">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 ">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-base font-semibold leading-6 text-gray-900">
                Allowance
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                A list of all the allowance
              </p>
            </div>
          </div>

          <div className="mt-4">
            <Filter />
          </div>
          <List />
        </div>
      </div>
    </Layout>
  );
}
