import { Layout } from "@components";
import { React } from "react";

import Filter from "./filter";
import RosterList from "./list";

export default function RosterPayables() {
  return (
    <Layout>
      <div className="py-10 lg:pl-72">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-base font-semibold leading-6 text-gray-900">
                Roster Payables
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                A list of all the roster payables
              </p>
            </div>
          </div>
        </div>
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-5">
          <Filter />
        </div>
        <RosterList />
      </div>
    </Layout>
  );
}
