import * as Yup from "yup";

import { gql, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";

import Select from "react-select";
import { useFormik } from "formik";
import useQueryParams from "../../utils/query_params";

const ADMIN_SALE_FILTER = gql`
  query (
    $client: String
    $personnel: String
    $program: String
    $status: String
  ) {
    adminSaleFilters(
      client: $client
      personnel: $personnel
      program: $program
      status: $status
    ) {
      clients {
        id
        clientName
      }
      personnels {
        id
        firstName
        lastName
      }
      programs {
        id
        clientName
        programName
      }
      status
    }
  }
`;

// Validation schema
const validationSchema = Yup.object({
  start_date: Yup.string().required("Required"),
  end_date: Yup.string().required("Required"),
});

export default function Filter(props) {
  const { getQueryParam, setQueryParam, setMultipleQueryParams } =
    useQueryParams();

  const { loading, error, data, refetch } = useQuery(ADMIN_SALE_FILTER, {
    variables: {
      client: getQueryParam("client") ? getQueryParam("client") : "all",
      personnel: "all",
      program: "all",
      status: "all",
    },
  });

  const capitalizeFirstLetter = (string) => {
    if (!string) return string; // Handle empty or null strings
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const [clientName, setClientName] = useState(
    getQueryParam("client")
      ? {
          label: capitalizeFirstLetter(getQueryParam("client")),
          value: getQueryParam("client"),
        }
      : { label: "All", value: "all" }
  );

  const [programName, setProgramName] = useState(
    getQueryParam("program")
      ? {
          label: capitalizeFirstLetter(getQueryParam("program")),
          value: getQueryParam("program"),
        }
      : { label: "All", value: "all" }
  );
  const [personnelName, setPersonnelName] = useState(
    getQueryParam("personnel")
      ? {
          label: capitalizeFirstLetter(getQueryParam("personnelLabel")),
          value: getQueryParam("personnel"),
        }
      : { label: "All", value: "all" }
  );

  const [status, setStatus] = useState(
    getQueryParam("status")
      ? {
          label: capitalizeFirstLetter(getQueryParam("status")),
          value: getQueryParam("status"),
        }
      : { label: "All", value: "all" }
  );

  const [statusOptions, setStausOptions] = useState([
    { label: "ALL", value: "ALL" },
    { label: "OPEN", value: "OPEN" },
    { label: "CLOSED", value: "CLOSED" },
  ]);

  const [clientNameOptions, setClientNameOptions] = useState([]);
  const [programNameOptions, setProgramNameOptions] = useState([]);
  const [personnelNameOptions, setPersonnelNameOptions] = useState([]);

  useEffect(() => {
    // Update formik values when data is available
    if (data && data.adminSaleFilters) {
      let programs = data.adminSaleFilters.programs.map((program) => ({
        label: program.programName,
        value: program.id,
      }));
      setProgramNameOptions([...[{ label: "All", value: "all" }], ...programs]);

      let clients = data.adminSaleFilters.clients.map((program) => ({
        label: program.clientName,
        value: program.id,
      }));
      setClientNameOptions([...[{ label: "All", value: "all" }], ...clients]);

      let personnels = data.adminSaleFilters.personnels.map((personnel) => ({
        label: `${personnel.firstName} ${personnel.lastName}`,
        value: personnel.id,
      }));

      setPersonnelNameOptions([
        ...[{ label: "All", value: "all" }],
        ...personnels,
      ]);
    }
  }, [data?.adminSaleFilters]);

  const formik = useFormik({
    initialValues: {
      client_name: "",
      program_name: "",
      personnel_name: "",
      status: "ALL",
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => {
      console.log({
        variables: {
          client_name: values.client_name,
          program_name: values.program_name,
          personnel_name: values.personnel_name,
          status: values.status,
        },
      });
    },
  });

  // useEffect(() => {
  //   let dates = formik.values;
  //   if (dates.start_date != "" && dates.end_date != "") {
  //     props.filter({
  //       page: 1,
  //       status: dates.status,
  //     });
  //     props.setPage(1);
  //   } else {
  //     props.filter({
  //       page: 1,
  //       status: dates.status,
  //     });
  //     props.setPage(1);
  //   }
  // }, [formik.values.start_date, formik.values.end_date, formik.values.status]);

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className="space-y-12">
          <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-12  items-start">
            <div className="sm:col-span-3">
              <label
                htmlFor="react-select-program_name-input"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Program Name
              </label>

              <div className="mt-2 space-y-2">
                <Select
                  autoFocus
                  options={programNameOptions}
                  name="program_name"
                  id="program_name"
                  instanceId="program_name"
                  value={{
                    label: programName.label,
                    value: programName.value,
                  }}
                  onChange={(selected) => {
                    formik.setFieldValue("program_name", selected.value);
                    setMultipleQueryParams({
                      program: selected.value,
                      page: 1,
                    });
                    setProgramName({
                      label: selected.label,
                      value: selected.value,
                    });
                    // setPersonnelName({ label: "All", value: "all" });
                    // setStatus({ label: "All", value: "all" });
                    // props.refetch({
                    //   page: 1,
                    //   client: clientName.value,
                    //   program: selected.value,
                    //   status: "all",
                    // });
                  }}
                  className="block w-full rounded-md text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />

                {formik.touched.client_name && formik.errors.client_name ? (
                  <div className="text-red-600 text-sm">
                    {formik.errors.client_name}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="react-select-client_name-input"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Client Name
              </label>

              <div className="mt-2 space-y-2">
                <Select
                  options={clientNameOptions}
                  name="client_name"
                  id="client_name"
                  instanceId="client_name"
                  value={{
                    label: clientName.label,
                    value: clientName.value,
                  }}
                  onChange={(selected) => {
                    formik.setFieldValue("client_name", selected.value);
                    setMultipleQueryParams({
                      client: selected.value,
                      page: 1,
                    });
                    setClientName(selected);
                    // props.refetch({
                    //   page: 1,
                    //   client: selected.value,
                    //   program: "all",
                    //   status: "all",
                    // });
                  }}
                  className="block w-full rounded-md text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />

                {formik.touched.client_name && formik.errors.client_name ? (
                  <div className="text-red-600 text-sm">
                    {formik.errors.client_name}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="react-select-personnel_name-input"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Personnel Name
              </label>

              <div className="mt-2 space-y-2">
                <Select
                  options={personnelNameOptions}
                  name="personnel_name"
                  id="personnel_name"
                  instanceId="personnel_name"
                  value={{
                    label: personnelName.label,
                    value: personnelName.value,
                  }}
                  onChange={(selected) => {
                    formik.setFieldValue("personnel_name", selected.value);
                    setMultipleQueryParams({
                      personnel: selected.value,
                      personnelLabel: selected.label,
                      page: 1,
                    });
                    setPersonnelName({
                      label: selected.label,
                      value: selected.value,
                    });
                  }}
                  className="block w-full rounded-md text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />

                {formik.touched.personnel_name &&
                formik.errors.personnel_name ? (
                  <div className="text-red-600 text-sm">
                    {formik.errors.personnel_name}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="react-select-status-input"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Status
              </label>

              <div className="mt-2 space-y-2">
                <Select
                  options={statusOptions}
                  name="status"
                  id="status"
                  instanceId="status"
                  value={{
                    label: status.label,
                    value: status.value,
                  }}
                  onChange={(selected) => {
                    formik.setFieldValue("status", selected.value);
                    setStatus(selected);
                    setMultipleQueryParams({
                      status: selected.value,
                      page: 1,
                    });
                  }}
                  className="capitalize block w-full rounded-md text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />

                {/* {formik.touched.status && formik.errors.status ? (
                  <div className="text-red-600 text-sm">
                    {formik.errors.status}
                  </div>
                ) : null} */}
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
